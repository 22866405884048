(function() {
    'use strict';

    var bp = {
        small: 640,
        medium: 800,
        large: 1280
    };

    var moveTo = new MoveTo();

    if (Modernizr.flexbox) {
        var portraitsSlider = new Swiper('#js-instructors-slider', {
            slidesPerView: 6,
            slidesPerGroup: 6,
            pagination: '.swiper-pagination',
            paginationClickable: true,
            breakpoints: {
                500: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 20
                },
                800: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                }
            }
        });
    }

    var app = window.app || {}
    var validCategories = app.validCategories || []

    var seminarList = {
        elem: document.getElementById('js-seminars'),
        iso: null,
        filters: null,
        filterValue: '*',
        validCategories: validCategories,
        isInit: false,
        init: function() {
            if (this.elem === null) { return; }
            if (this.isInit === true) { return; }

            var filter = '*';
            var pathname = document.location.pathname;

            if (pathname.indexOf('tag:') > -1) {
                var tag = pathname.substring(pathname.indexOf(':') + 1);
                if (seminarList.validCategories.indexOf(tag) > -1) {
                    filter = tag;
                }
            }

            seminarList.filterValue = filter;

            // initialize isotope library
            this.iso = new Isotope(this.elem, {
                itemSelector: '.seminar-list__item',
                layoutMode: 'fitRows',
                filter: seminarList._filter,
                hiddenStyle: {opacity: 0},
                visibleStyle: {opacity: 1}
            });


            this.filters = document.querySelectorAll('.seminar-list__filter a');

            // setup event handlers on filter nav
            this._bindFilters();

            this.isInit = true;
        },
        destroy: function() {
            if (this.isInit === false) { return; }
            this.iso.destroy();
            this._unbindFilters();
            this.isInit = false;
        },
        _bindFilters: function() {
            [].forEach.call(this.filters, function(item) {
                item.addEventListener('click', seminarList._clickHandler);
            });
        },
        _unbindFilters: function() {
            [].forEach.call(this.filters, function(item) {
                item.removeEventListener('click', seminarList._clickHandler);
            });
        },
        _filter: function(itemElem) {
            if (seminarList.filterValue === '*') {
                return true;
            }

            var itemCategory = itemElem.getAttribute('data-category');

            if (itemCategory && itemCategory.indexOf(seminarList.filterValue) > -1) {
                return true;
            }

            return false;
        },
        _clickHandler: function(e) {
            e.preventDefault();

            // set active state
            [].forEach.call(seminarList.filters, function(item) {
                item.classList.remove('active');
            });
            this.classList.add('active');

            // get filter value
            var filter = this.getAttribute('data-filter');
            if (filter === null || filter === '') {
                return false;
            }

            // rearrange seminar list
            seminarList.filterValue = filter
            seminarList.iso.arrange({
                filter: seminarList._filter
            });
        }
    };
    // first init
    if (window.innerWidth > bp.small) {
        seminarList.init();
    }
    window.addEventListener('resize', function() {
        if (window.innerWidth < bp.small) {
            seminarList.destroy();
        } else {
            seminarList.init();
        }
    });

    // setup moveto links
    var triggers = document.getElementsByClassName('js-move-to');
    Array.prototype.forEach.call(triggers, function(trigger) {
        moveTo.registerTrigger(trigger);
    });

    var SignupForm = function(form) {
        this.form = form;
        form.addEventListener('submit', this._onSubmit.bind(this));
    };
    SignupForm.prototype._onSubmit = function() {
        var $submit = this.form.querySelector('.js-submit-btn');
        $submit.setAttribute('disabled', 'disabled');
        $submit.style.width = $submit.getBoundingClientRect().width + 'px';
        $submit.setAttribute('value', 'Sendet…');
    };
    var signupForm = document.getElementById('js-signup-form');
    if (signupForm) {
        new SignupForm(signupForm);
    }

    // include Tidio
    setTimeout(function() {
        var tidioScript = document.createElement('script');
        tidioScript.src = '//code.tidio.co/hnlsqcpzhozztvbxowygymwnid8uuapd.js';
        document.body.appendChild(tidioScript);
    }, 2000);

})();
